<template>
  <div class="custom-container">
    <div class="p-3">
      <!-- Periodo a cargar -->
      <div class="row mb-5">
        <div class="col-12">
          <label for="periodo">Periodo a cargar </label><br />
          <div class="d-flex flex-column  flex-lg-row align-items-lg-center justify-content-lg-between">
            <div class="d-flex flex-column flex-lg-row align-items-lg-center">
              <b-form-select id="periodo" v-model="selectedPeriodo" :options="periodos" value-field="idMes"
                text-field="nombreMes">
                <template #first>
                  <b-form-select-option selected :value="null" disabled>
                    -- selecciona una opción --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <vs-button style="width: 2.5rem; height:2.5rem;"   success class="px-3" @click="guardar" id="saveform2">
                <i class="fas fa-floppy-disk   "  style="font-size: 1.5rem;"></i>
                <!-- Guardar -->
              </vs-button>
            </div>


            <vs-button primary class="px-3" @click="sendReport" id="sendform2">
              <i class="fas fa-paper-plane mr-1"></i>
              Enviar a ST
            </vs-button>
          </div>
          <label for="info" class="text-danger">*Al enviar a ST, se enviará la información <br>
            solo del periodo seleccionado.</label>
        </div>
      </div>

      <!-- Tabs de formularios -->
      <b-tabs content-class="mt-1" v-model="tabIndex" pills id="formFase2">
        <b-tab v-for="(tab, index) in tabsForms" :key="index" :title="`${tab.id}. ${tab.title}`" :active="index === 0"
          :title-link-class="linkClass(index)">
          <form v-if="tab.name !== 'files'">
            <!-- Indicadores -->
            <div class="row mx-1 py-3" style="gap: 0.5rem; border-bottom:solid 1px #6272ff;" v-b-toggle="'collapse-ind' + tab.id">
              <div class="col-12 col-md-2">
                <i :class="`fas fa-caret-${collapsed['ind' + tab.id] ? 'up' : 'down'
                  }`"></i>
                <b> Variable del indicador </b>
              </div>
              <div class="col-12 col-md-4">
                <b> Indicadores </b>
              </div>
              <div class="col">
                <b> Valores </b>
              </div>
            </div>
            <b-collapse v-model="collapsed['ind' + tab.id]" visible class="px-4 py-3" :id="'collapse-ind' + tab.id">
              <row-indicadores :selected-periodo="handlerSelectedPeriodo" :currencyModel="tab.name"
                :trimestralSelected="trimestralSelected" />
            </b-collapse>

            <!-- Evidencias -->
            <div v-if="showEvidencias(tab)" class="row      mx-1 py-3" style="gap: 0.5rem; border-bottom:solid 1px #6272ff;"
              v-b-toggle="'collapse-evid' + tab.id">
              <div class="col-12 col-md-1">
                <i :class="`fas fa-caret-${collapsed['evid' + tab.id] ? 'up ' : 'down'
                  }`"></i>
                <b> Nro </b>
              </div>
              <div class="col-12 col-md-3">
                <b> Descripción de las evidencias </b>
              </div>
              <div class="col-12 col-md-2">
                <b> Tipo de evidencia </b>
              </div>
              <div class="col-2">
                <b> Evidencia </b>
              </div>
            </div>
            <b-collapse v-if="showEvidencias(tab)" v-model="collapsed['evid' + tab.id]" class="px-4 py-3"
              :id="'collapse-evid' + tab.id">
              <row-evidencias :periodo="selectedPeriodo" :usuario="userLogged" :currencyModel="tab.name"
                :trimestralSelected="trimestralSelected" :vigencia="$route.query.vigencia"
                :numeroReporte="parseInt($route.query.reporte)" @handleFilesSisi2="handleFilesSisi2" />
            </b-collapse>
          </form>

          <list-files-fase2 v-if="tab.name === 'files'" :usuario="userLogged" :periodo="selectedPeriodo" />
        </b-tab>

        <b-tab :title-link-class="linkClass(4)">
          <template #title> Archivos <i class="far fa-file"></i> </template>
          <list-files-fase2 v-if="tabIndex === 4" :usuario="userLogged" :periodo="selectedPeriodo"
            :numeroReporte="parseInt($route.query.reporte)" />
        </b-tab>
      </b-tabs>

      <!-- Navegación entre pasos -->
      <step-navigation-with-scroll :forms-steps="tabsForms" :active-step="tabIndex" @update-steps="tabIndex = $event"
        @guardar="guardar" />
    </div>
  </div>
</template>

<script>
import RowIndicadores from "./pesv_fase2_components/RowIndicadores.vue";
import RowEvidencias from "./pesv_fase2_components/RowEvidencias.vue";
import StepNavigationWithScroll from "./pesv_fase2_components/StepNavigationWithScroll.vue";
import ListFilesFase2 from "./pesv_fase2_components/ListFilesFase2.vue";


import { mapActions, mapState } from "vuex";

// alert
const Swal = require("sweetalert2");

export default {
  components: {
    RowIndicadores,
    RowEvidencias,
    StepNavigationWithScroll,
    ListFilesFase2,
  },
  data() {
    return {
      userLogged: null,
      isSisiAuth: false,
      selectedPeriodo: 1,
      selectedPeriodos: [],
      collapsed: {},
      tabIndex: 0,
      periodos: [],
    };
  },
  async created() {
    // obtener usuario activo
    this.userLogged = JSON.parse(localStorage.getItem("setPersonaAct"));

    this.tabsForms.forEach((tab) => {
      this.$set(this.collapsed, "ind" + tab.id, true);
      this.$set(this.collapsed, "evid" + tab.id, true);
    });

    this.$store.dispatch("initFormsFase2");

    await this.initializeData();
  },
  computed: {
    ...mapState("pesv", ["TOKEN_SISI", "credential"]),
    tabsForms() {
      return [
        {
          id: "A",
          name: "formSiniestros",
          title: "Formulario sobre Siniestros",
        },
        {
          id: "B",
          name: "formAdministrativo",
          title: "Formulario Administrativo",
        },
        {
          id: "C",
          name: "formFuncionamiento",
          title: "Formulario de Funcionamiento",
        },
        {
          id: "D",
          name: "formVehiculos",
          title: "Formulario de Vehículos",
        },
      ];
    },
    trimestralSelected() {
      return [3, 6, 9, 12].includes(this.selectedPeriodo);
    },
    handlerSelectedPeriodo() {
      const res = this.selectedPeriodos.find(periodo => this.selectedPeriodo === periodo.idMes);
      return res?.text;
    },
  },
  watch: {
    selectedPeriodo() {
      this.getData();
    },
  },

  methods: {
    ...mapActions("pesv", ["useRequestSisi"]),
    async initializeData() {

      // Verificar si existe autenticación de Sisi
      const isSisiAuth = localStorage.getItem("sisiAuth");

      this.isSisiAuth = isSisiAuth ? true : false;

      if (!isSisiAuth) {
        Swal.fire(
          "Error!",
          "No se encontraron datos de autenticación de Sisi",
          "error"
        );
        return;
      }

      await this.getMesesVigencia();

      await this.getData();

    },
    async getMesesVigencia() {
      // obtener lista de reportes de Sisi
      const mesesVigengia = await this.useRequestSisi({
        method: "GET",
        path: "maestras/meses",
        params: {
          vigencia: this.$route.query.vigencia,
          historico: false,
        },
      });

      if (!mesesVigengia.ok) {
        this.$isLoading(false);
        Swal.fire(
          "Error!",
          "No se pudo obtener los meses de vigencia de Sisi",
          "error"
        );
        return;
      }

      const periodos = mesesVigengia.data.meses.map(mes => {
        return {
          idMes: mes.idMes,
          nombreMes: mes.idMes + " - " + mes.nombreMes
        }
      });

      const str = {
        1: "Enero",
        2: "Febrero",
        3: "Enero - Marzo",
        4: "Abril",
        5: "Mayo",
        6: "Abril - Junio",
        7: "Julio",
        8: "Agosto",
        9: "Julio - Septiembre",
        10: "Octubre",
        11: "Noviembre",
        12: "Octubre - Diciembre",
      };

      const selectedPeriodos = mesesVigengia.data.meses.map(mes => {
        const nombreMes = str[mes.idMes] || mes.nombreMes;
        return {
          idMes: mes.idMes,
          text: this.trimestralSelected ? `${nombreMes} - ${nombreMes}` : str[mes.idMes]
        };
      });

      this.periodos = periodos
      this.selectedPeriodos = selectedPeriodos

    },
    linkClass(idx) {
      return this.tabIndex === idx
        ? ["bg-danger  mx-1 px-4", "text-light", "font-weight-bold"]
        : ["bborder rounded-0 border-bottom mx-1 text-text px-4", "font-weight-bold"];
    },
    showEvidencias(tab) {
      return tab.id !== "B" || this.trimestralSelected;
    },
    async getData() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("hl_get", {
          path: "PesvFase2/GetAllData/" + this.userLogged.empresa.id + "/" + this.$route.query.reporte,
        });

        const filterPeriodo = {};
        for (const item in res) {
          filterPeriodo[item] = res[item].filter(
            (x) => x.periodo === this.selectedPeriodo
          );
        }
        this.$store.dispatch("updateFormsFase2", filterPeriodo);
        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        Swal.fire("Error!", error, "error");
        console.log("err", error);
      }
    },

    async handleFilesSisi2(filesData) {

      if (!this.isSisiAuth) {
        Swal.fire(
          "Error!",
          "No se encontraron datos de autenticación de Sisi",
          "error"
        );
        return;
      }

      // obtener nombre del archivo
      const fileName = filesData.file.name;

      // obtener extensión del archivo
      const extension = fileName.slice(fileName.lastIndexOf("."));

      // Subir archivos a Sisi
      const formData = new FormData();
      formData.append("archivo", filesData.file);
      formData.append("idVigilado", this.credential.usuario);
      formData.append("extension", extension);
      formData.append("token", this.TOKEN_SISI);

      const fileUploadResponse = await this.useRequestSisi({
        method: "POST",
        path: "archivos/evidencias",
        body: formData,
        isFormData: true
      });

      if (!fileUploadResponse.ok) {
        Swal.fire(
          "Error!",
          "No se pudo subir el archivo al servidor de Sisi",
          "error"
        );
        return;
      }
      const newItem = {
        data: fileUploadResponse.data,
        numeroEvidencia: filesData.numeroEvidencia,
        form: filesData.form
      };
      let filesSent = JSON.parse(localStorage.getItem("filesSentFase2")) || [];

      const index = filesSent.findIndex(item => item.numeroEvidencia === newItem.numeroEvidencia && item.form === newItem.form);

      if (index !== -1) {
        filesSent[index] = newItem;
      } else {
        filesSent.push(newItem);
      }

      // Guardar la lista actualizada en localStorage
      localStorage.setItem("filesSentFase2", JSON.stringify(filesSent));
    },
    async sendReport() {

      if (!this.isSisiAuth) {
        Swal.fire(
          "Error!",
          "No se encontraron datos de autenticación de Sisi",
          "error"
        );
        return;
      }

      this.$isLoading(true);
      const body = {
        idEncuesta: "2",
        idReporte: this.$route.query.reporte,
        idVigilado: this.credential.usuario,
        idMes: this.selectedPeriodo,
        token: this.TOKEN_SISI,
      }

      // enviar reporte a Sisi
      const sendRespuesta = await this.useRequestSisi({
        method: "POST",
        path: "inidicador/enviar-reporte",
        body
      });

      if (!sendRespuesta.ok) {
        Swal.fire("Notificación!", sendRespuesta.data.mensaje, "warning");
        this.$isLoading(false);
        return
      }

      Swal.fire(
        "Listo!",
        `Se ha enviado el formulario correctamente.`,
        "success"
      );

      this.$isLoading(false);
    },
    obtenerDatosDeFormularios() {
      const data = {};

      this.tabsForms.forEach(({ name }) => {
        const formData = this.$store.state.formsPesvFase2[name];
        if (formData) {
          const respuestas = {};
          const evidencias = {};

          formData.indicadores.forEach((item) => {
            respuestas[item.name] = item.value;
          });

          formData.evidencias.forEach((item) => {
            if (item.name) {
              evidencias[item.name] = item.value;
            }
          });

          data[name] = {
            Periodo: this.selectedPeriodo,
            EmpresaId: this.userLogged.empresa.id,
            NumeroReporte: this.$route.query.reporte,
            Vigencia: this.$route.query.vigencia,
            Fecha: this.$moment
              .tz(new Date(), "America/Costa_Rica")
              .format("YYYY-MM-DDTHH:mm:ssZ"),
            ...respuestas,
            ...(name === 'formVehiculos' ? evidencias : {})
          };
        }
      });

      return data;
    },

    processForms(formularios) {
      let subIndicadores = {};
      let evidencias = {};

      const procesarEvidencias = (tipo, form) => {
        let respuestas = form.evidencias;

        respuestas.forEach((resp) => {
          const { consecutivo, idEvidencia, respuesta, tipoEvidencia } = resp;
          let newItem = { consecutivo, idEvidencia, tipoEvidencia, valor: respuesta };

          if (!evidencias[tipo]) {
            evidencias[tipo] = [];
          }

          evidencias[tipo].push(newItem);
        });
      }

      const procesarSubIndicadores = (tipo, form) => {
        let respuestas = form.subIndicador;

        respuestas.forEach((resp) => {
          const { codigo, preguntas } = resp;
          let newItem = {
            codigo,
            preguntaId: preguntas[0].idPregunta,
            valor: preguntas[0].respuesta
          };

          if (!subIndicadores[tipo]) {
            subIndicadores[tipo] = [];
          }

          subIndicadores[tipo].push(newItem);
        });
      }

      formularios.forEach((form) => {
        if (form.nombre.includes('Siniestros')) {
          procesarSubIndicadores('formSiniestros', form);
          procesarEvidencias('formSiniestros', form);
        }

        if (form.nombre.includes('Administrativo')) {
          procesarSubIndicadores('formAdministrativo', form);
          procesarEvidencias('formAdministrativo', form);
        }

        if (form.nombre.includes('Funcionamiento')) {
          procesarSubIndicadores('formFuncionamiento', form);
          procesarEvidencias('formFuncionamiento', form);
        }

        if (form.nombre.includes('Vehículos')) {
          procesarSubIndicadores('formVehiculos', form);
          procesarEvidencias('formVehiculos', form);
        }
      });


      return { subIndicadores, evidencias };
    },
    processSubIndicadores(subIndicadores, dataForms) {
      // actualizar los valores
      const updateValues = (originalArray, newValuesObject) => {

        if (!originalArray || !Array.isArray(originalArray)) {
          return;
        }

        originalArray.forEach(item => {
          let key = `v${item.codigo.replace('.', '_')}`;
          if (Object.prototype.hasOwnProperty.call(newValuesObject, key)) {
            item.valor = newValuesObject[key];
          }
        });
      }

      // Actualizar los arrays con los nuevos valores
      updateValues(subIndicadores.formSiniestros, dataForms.formSiniestros);
      updateValues(subIndicadores.formAdministrativo, dataForms.formAdministrativo);
      updateValues(subIndicadores.formFuncionamiento, dataForms.formFuncionamiento);
      updateValues(subIndicadores.formVehiculos, dataForms.formVehiculos);


      // Unir los arrays
      let combinedArray = [
        ...(Array.isArray(subIndicadores.formFuncionamiento) ? subIndicadores.formFuncionamiento : []),
        ...(Array.isArray(subIndicadores.formVehiculos) ? subIndicadores.formVehiculos : []),
        ...(Array.isArray(subIndicadores.formSiniestros) ? subIndicadores.formSiniestros : []),
        ...(Array.isArray(subIndicadores.formAdministrativo) ? subIndicadores.formAdministrativo : []),
      ];

      const finalRespuestas = combinedArray.filter(item => item.valor !== "");

      return finalRespuestas
    },
    processEvidencias(evidencias, dataForms) {
      const filesSent = JSON.parse(localStorage.getItem("filesSentFase2")) || [];

      const filesSentTransformed = filesSent.map(file => ({
        evidenciaId: evidencias[file.form].find(e => e.consecutivo === file.numeroEvidencia).idEvidencia,
        documento: file.data.nombreAlmacenado,
        nombreArchivo: file.data.nombreOriginalArchivo,
        ruta: file.data.ruta,
        valor: ""
      }));


      const formVehiculos = dataForms.formVehiculos;
      const numberEvidences = evidencias.formVehiculos.filter(e => e.tipoEvidencia === 'NUMBER');

      const numberValuesTransformed = numberEvidences.map(evidence => ({
        evidenciaId: evidence.idEvidencia,
        valor: formVehiculos[`e_${evidence.consecutivo}`] || ""
      }));

      const finalEvidencias = [
        ...filesSentTransformed,
        ...numberValuesTransformed
      ];

      return finalEvidencias
    },
    async guardar() {

      if (!this.isSisiAuth) {
        Swal.fire(
          "Error!",
          "No se encontraron datos de autenticación de Sisi",
          "error"
        );
        return
      }
      try {
        this.$isLoading(true);

        const dataForms = this.obtenerDatosDeFormularios();

        // obtener lista de reportes de Sisi fase 2
        const encuestasResponse = await this.useRequestSisi({
          method: "GET",
          path: "inidicador/obtener-formularios",
          params: {
            idReporte: this.$route.query.reporte,
            idVigilado: this.credential.usuario,
            idMes: this.selectedPeriodo,
            historico: false,
            token: this.TOKEN_SISI,
          },
        });

        if (!encuestasResponse.ok) {
          this.$isLoading(false);
          Swal.fire("Notificación!", "Ha ocurrido un error. Intenta de nuevo.", "warning");
          return
        }


        const formularios = encuestasResponse.data.formularios;


        const { subIndicadores, evidencias } = this.processForms(formularios);


        const finalRespuestas = this.processSubIndicadores(subIndicadores, dataForms);

        const finalEvidencias = this.processEvidencias(evidencias, dataForms);


        const body = {
          idVigilado: this.credential.usuario,
          token: this.TOKEN_SISI,
          reporteId: this.$route.query.reporte,
          respuestas: finalRespuestas,
          evidencias: finalEvidencias,
          mesId: this.selectedPeriodo
        }

        // Guardar respuestas en Sisi
        const saveRespuestas = await this.useRequestSisi({
          method: "POST",
          path: "inidicador/guardar-respuestas",
          body
        });

        if (!saveRespuestas.ok) {
          this.$isLoading(false);
          Swal.fire("Notificación!", saveRespuestas.data.mensaje, "warning");
          return
        }

        // guardar datos en helexium
        let res = await this.$store.dispatch("hl_post", {
          path: "PesvFase2/",
          data: dataForms,
        });

        if (!res.success) {
          this.$isLoading(false);
          Swal.fire("Error!", "Ocurrio un error, intentalo nuevamente", "error");
          return
        }

        Swal.fire("Listo!", saveRespuestas.data.mensaje, "success");

        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        Swal.fire("Error!", error, "error");
        console.log(error);
      }
    },
  },
};
</script>

<style>
.incline {
  transform: rotate(-40deg);
}
</style>
