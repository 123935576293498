<script>
import modalIframe from '@/components/helexium/modalIframe.vue'

export default {
    name: "PowerBiIframe",
    components: {
        modalIframe
    },
    props: {
        user: {
            type: Object,
            required: true
        },

        reportData: {
            type: Object,
            required: true
        },

        title: {
            type: String,
            default: 'Reporte'
        },
    },
    data() {
        return {
            isCopy: false,
            showPassword: false,
            isNotFullScreen: true,
        }
    },
    computed: {
        passwordReport() {
            if (this.showPassword) {
                return this.user.contrasenaReportes;
            }
            else {
                return "*****";
            }
        }
    },
    methods: {
        copyPassword() {
            navigator.clipboard.writeText(this.user.contrasenaReportes);
            this.isCopy = true
            setTimeout(() => { this.isCopy = false }, 1000)
        }
    }
}

</script>
<template>
    <div>
        <div class="btn-controle-full-size" @click="isNotFullScreen = false" v-show="isNotFullScreen"></div>
        <modalIframe 
        :url="reportData.url" 
        :likeElement="isNotFullScreen" 
        @close="isNotFullScreen = true"
        :showHeader="!isNotFullScreen"
        >
            <template #header-left>
                <span>{{ title }}</span> |
                <span @click="showPassword = !showPassword" class="mr-1">
                    <i v-show="!showPassword" class="fas fa-eye-slash eye"></i>
                    <i v-show="showPassword" class="fas fa-eye"></i>
                </span>
                <span @click="copyPassword" class="pointer mr-1">
                    {{ passwordReport }}
                </span>
                <span v-show="isCopy">
                    <i class="fas fa-check text-success"></i>
                </span>
            </template>
        </modalIframe>
    </div>
</template>
<style scoped>
/* .container-iframe{
  width: 100%;
  min-height: 70vh;
  position: relative;
} */

.btn-controle-full-size {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: transparent;
    cursor: pointer;
}
</style>