<template>
  <div>
    <div class="d-flex justify-content-between" style="width: 100%">
      <vs-button id="save" success class="m-3 mb-0" type="submit">
        <i class="fas fa-check-circle mr-1"></i>
        Guardar
      </vs-button>

      <vs-button id="send" primary class="m-3 mb-0" type="button" @click="sendReport">
        <i class="fas fa-paper-plane mr-1"></i>
        Enviar a ST
      </vs-button>
    </div>
    <div class="text-left p-4">
      <h4 class="font-weight-bold mb-3 text-secondary">Fases</h4>
      <template v-if="stepItems">
        <div class="m-0" v-for="Item in stepItems" :key="Item.Id" id="formFase">
          <div style="width: 40rem;" class="text-light py-3 pl-1   border-bottom" v-b-toggle="'collapse-' + Item.Id">
            <i style="font-size: 20px " class="mr-2"  :class="`fas fa-caret-${Item.collapsed ? 'up ' : 'down'
              } mr-1`"></i>
            <b>{{ Item.DescripcionPaso }}</b>
          </div>
          <b-collapse v-model="Item.collapsed" visible :id="'collapse-' + Item.Id" class="px-4 py-3">
            <p style="line-height: 1.5; text-align: justify">
              {{ Item.ContextoPaso }}
            </p>
            <div class="mt-3">
              <div class="row" style="gap: 1rem">
                <!-- existe? -->
                <b-form-select class="col-12 col-lg" v-model="Item.value" value-field="id" text-field="text"
                  :options="options" :key="Item.Id" :id="'select-' + Item.id">
                  <template #first>
                    <b-form-select-option selected :value="undefined" disabled>
                      -- selecciona una opción --
                    </b-form-select-option>
                  </template>
                </b-form-select>

                <!-- motivo -->
                <b-form-select class="col-12 col-lg" v-model="Item.motivo" value-field="id" text-field="text"
                  :options="motivos" :key="Item.Id + '_motivo'" :id="'select-' + Item.id + '_motivo'">
                  <template #first>
                    <b-form-select-option selected :value="undefined" disabled>
                      -- selecciona motivo --
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </div>
              <div class="mt-3">
                <span class="text-light">
                  Cargar plan de mejoramiento
                </span>
              </div>
              <div class="mt-3">
                <div>
                  <DropFile @handleFilesSisi="handleFilesSisi"
                    :urlDir="`users/pesv/evaluaciones/${userLogged.empresa.id}/${item.id}/${Item.FaseId}/${Item.Id}`"
                    :pathGet="`PesvPasosEvaluacion/GetPesvListaArchivosId/${item.id}/${Item.Id}/${Item.FaseId}`"
                    pathPost="PesvPasosEvaluacion/CreatePesvPasoEvaluacionArchivo" :injectData="{
                      PesvEvaluacionId: item.id,
                      PasoId: Item.Id,
                      FaseId: Item.FaseId,
                      FechaArchivo: $moment
                        .tz(new Date(), 'America/Costa_Rica')
                        .format('YYYY-MM-DDTHH:mm:ssZ'),
                    }" :fileTypes="[
                      'application/pdf',
                      'application/docx',
                      'image/jpeg',
                      'image/png',
                      'image/gif',
                      'application/msword',
                      'application/vnd.ms-excel',
                      'application/vnd.ms-powerpoint',
                      'text/plain',
                      'image/*',
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                      'application/vnd.ms-powerpoint',
                      'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
                      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                      'application/vnd.ms-excel',
                    ]" />
                </div>
              </div>
            </div>
          </b-collapse>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import DropFile from "@/components/helexium/DropFileV2.vue";
export default {
  components: {
    DropFile,
  },
  props: {
    stepItems: Array,
    item: Object,
  },
  data() {
    return {
      userLogged: null,
      options: [
        { id: 1, text: "Cumple" },
        { id: 2, text: "No cumple" },
      ],
      motivos: [
        { id: 1, text: "No es vigilado de la Superintendencia de Transporte." },
        {
          id: 2,
          text: "La autoridad competente para la verificación del PESV no es la Superintendencia de Transporte.",
        },
        {
          id: 3,
          text: "No aplica al nivel de diseño e implementación del PESV categorizado para la organización.",
        },
        { id: 4, text: "No existe obligación de presentar la información." },
        {
          id: 5,
          text: "Desconocimiento de la fecha límite para el diseño e implementación del PESV.",
        },
        {
          id: 6,
          text: "Desconocimiento de los lineamientos de la Resolución 40595 de 2022 y demás normatividad aplicable.",
        },
      ],
    };

  },
  mounted() {
    this.userLogged = JSON.parse(localStorage.getItem("setPersonaAct"));
  },
  methods: {
    handleFilesSisi(data) {
      this.$emit("handleFilesSisi", data);
    },
    sendReport() {
      this.$emit("sendReport");
    }
  },
};
</script>

<style>
.incline {
  transform: rotate(-40deg);
}
</style>
