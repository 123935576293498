var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-content-between",staticStyle:{"width":"100%"}},[_c('vs-button',{staticClass:"m-3 mb-0",attrs:{"id":"save","success":"","type":"submit"}},[_c('i',{staticClass:"fas fa-check-circle mr-1"}),_vm._v(" Guardar ")]),_c('vs-button',{staticClass:"m-3 mb-0",attrs:{"id":"send","primary":"","type":"button"},on:{"click":_vm.sendReport}},[_c('i',{staticClass:"fas fa-paper-plane mr-1"}),_vm._v(" Enviar a ST ")])],1),_c('div',{staticClass:"text-left p-4"},[_c('h4',{staticClass:"font-weight-bold mb-3 text-secondary"},[_vm._v("Fases")]),(_vm.stepItems)?_vm._l((_vm.stepItems),function(Item){return _c('div',{key:Item.Id,staticClass:"m-0",attrs:{"id":"formFase"}},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('collapse-' + Item.Id),expression:"'collapse-' + Item.Id"}],staticClass:"text-light py-3 pl-1 border-bottom",staticStyle:{"width":"40rem"}},[_c('i',{staticClass:"mr-2",class:`fas fa-caret-${Item.collapsed ? 'up ' : 'down'
            } mr-1`,staticStyle:{"font-size":"20px"}}),_c('b',[_vm._v(_vm._s(Item.DescripcionPaso))])]),_c('b-collapse',{staticClass:"px-4 py-3",attrs:{"visible":"","id":'collapse-' + Item.Id},model:{value:(Item.collapsed),callback:function ($$v) {_vm.$set(Item, "collapsed", $$v)},expression:"Item.collapsed"}},[_c('p',{staticStyle:{"line-height":"1.5","text-align":"justify"}},[_vm._v(" "+_vm._s(Item.ContextoPaso)+" ")]),_c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"row",staticStyle:{"gap":"1rem"}},[_c('b-form-select',{key:Item.Id,staticClass:"col-12 col-lg",attrs:{"value-field":"id","text-field":"text","options":_vm.options,"id":'select-' + Item.id},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"selected":"","value":undefined,"disabled":""}},[_vm._v(" -- selecciona una opción -- ")])]},proxy:true}],null,true),model:{value:(Item.value),callback:function ($$v) {_vm.$set(Item, "value", $$v)},expression:"Item.value"}}),_c('b-form-select',{key:Item.Id + '_motivo',staticClass:"col-12 col-lg",attrs:{"value-field":"id","text-field":"text","options":_vm.motivos,"id":'select-' + Item.id + '_motivo'},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"selected":"","value":undefined,"disabled":""}},[_vm._v(" -- selecciona motivo -- ")])]},proxy:true}],null,true),model:{value:(Item.motivo),callback:function ($$v) {_vm.$set(Item, "motivo", $$v)},expression:"Item.motivo"}})],1),_c('div',{staticClass:"mt-3"},[_c('span',{staticClass:"text-light"},[_vm._v(" Cargar plan de mejoramiento ")])]),_c('div',{staticClass:"mt-3"},[_c('div',[_c('DropFile',{attrs:{"urlDir":`users/pesv/evaluaciones/${_vm.userLogged.empresa.id}/${_vm.item.id}/${Item.FaseId}/${Item.Id}`,"pathGet":`PesvPasosEvaluacion/GetPesvListaArchivosId/${_vm.item.id}/${Item.Id}/${Item.FaseId}`,"pathPost":"PesvPasosEvaluacion/CreatePesvPasoEvaluacionArchivo","injectData":{
                    PesvEvaluacionId: _vm.item.id,
                    PasoId: Item.Id,
                    FaseId: Item.FaseId,
                    FechaArchivo: _vm.$moment
                      .tz(new Date(), 'America/Costa_Rica')
                      .format('YYYY-MM-DDTHH:mm:ssZ'),
                  },"fileTypes":[
                    'application/pdf',
                    'application/docx',
                    'image/jpeg',
                    'image/png',
                    'image/gif',
                    'application/msword',
                    'application/vnd.ms-excel',
                    'application/vnd.ms-powerpoint',
                    'text/plain',
                    'image/*',
                    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                    'application/vnd.ms-powerpoint',
                    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
                    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'application/vnd.ms-excel',
                  ]},on:{"handleFilesSisi":_vm.handleFilesSisi}})],1)])])])],1)}):_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }