<template>
  <div>
    <div v-if="showOverlay" class="overlay"></div>
    <div v-if="showTour" class="tour">
      <div
        v-if="currentStep"
        class="tooltip-tour"
        :style="{
          top: tooltipPosition.top + 'px',
          left: tooltipPosition.left + 'px',
          cursor: isDragging ? 'grabbing' : 'grab',
        }"
        @mousedown="startDragging"
        @touchstart="startDragging"
        @mousemove="drag"
        @touchmove="drag"
        @mouseup="endDragging"
        @touchend="endDragging"
        @mouseleave="endDragging"
      >
        <div class="tooltip-tour-content">
          <div class="pr-2">
            <h4 class="my-3">
              <b>{{ currentStep.title }}</b>
            </h4>
            <p>
              {{ currentStep.description }}
            </p>
          </div>
          <div class="progress-tour">
            <span
              v-for="(step, index) in steps"
              :key="index"
              class="step-dot"
              :class="{
                completed: index < currentStepIndex,
                active: index === currentStepIndex,
              }"
              @click="goToStep(index)"
            ></span>
          </div>
          <div class="buttons">
            <button
              @click="nextStep"
              class="next-button fas fa-chevron-right text-white"
              v-if="currentStepIndex < steps.length - 1"
            ></button>
            <button
              @click="endTour"
              class="end-button fas fa-check text-white"
              v-if="currentStepIndex === steps.length - 1"
            ></button>
            <i class="close-button fas fa-xmark" @click="endTour"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrollTimeout: null,
      currentStepIndex: 0,
      steps: [],
      showOverlay: false,
      showTour: false,
      currentStepPosition: { top: 0, left: 0 },
      isDragging: false,
      initialTouchPos: { x: 0, y: 0 },
      initialMousePos: { x: 0, y: 0 },
      initialTooltipPos: { top: 0, left: 0 },
      tooltipPosition: { top: 0, left: 0 },
    };
  },
  mounted() {
    window.addEventListener("resize", this.updateTooltipPosition);
    window.addEventListener("scroll", this.updateTooltipPosition);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateTooltipPosition);
    window.removeEventListener("scroll", this.updateTooltipPosition);
  },
  methods: {
    startDragging(event) {
      this.isDragging = true;
      if (event.type === "touchstart") {
        const touch = event.touches[0];
        this.initialTouchPos = { x: touch.clientX, y: touch.clientY };
      } else {
        this.initialMousePos = { x: event.clientX, y: event.clientY };
      }
      this.initialTooltipPos = { ...this.tooltipPosition };
    },
    drag(event) {
      if (this.isDragging) {
        // Desactivar la transición durante el arrastre
        this.disableTransition();

        let clientX, clientY;
        if (event.type === "touchmove") {
          const touch = event.touches[0];
          clientX = touch.clientX;
          clientY = touch.clientY;
        } else {
          clientX = event.clientX;
          clientY = event.clientY;
        }

        // Calcular la diferencia en la posición del ratón
        const diffX = clientX - this.initialMousePos.x;
        const diffY = clientY - this.initialMousePos.y;

        // Calcular la nueva posición del tooltip
        let newTop = this.initialTooltipPos.top + diffY;
        let newLeft = this.initialTooltipPos.left + diffX;

        // Obtener las dimensiones de la ventana del navegador
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        // Limitar la posición del tooltip para que no se salga de los límites de la ventana
        const tooltipWidth =
          document.querySelector(".tooltip-tour").offsetWidth;
        const tooltipHeight =
          document.querySelector(".tooltip-tour").offsetHeight;
        const buttonWidth = 40;

        newTop = Math.min(Math.max(newTop, 0), viewportHeight - tooltipHeight);
        newLeft = Math.min(
          Math.max(newLeft, 0),
          viewportWidth - tooltipWidth - buttonWidth
        );

        this.tooltipPosition = {
          top: newTop,
          left: newLeft,
        };
      }
    },

    disableTransition() {
      const tooltip = document.querySelector(".tooltip-tour");
      tooltip.style.transition = "none";
    },
    enableTransition() {
      const tooltip = document.querySelector(".tooltip-tour");
      tooltip.style.transition = "";
    },
    endDragging() {
      this.isDragging = false;
      // Volver a activar la transición al finalizar el arrastre
      this.enableTransition();
    },
    goToStep(index) {
      if (index >= 0 && index < this.steps.length) {
        this.removeHighlight();
        this.currentStepIndex = index;
        this.focusElement();
      }
    },
    startTour(steps) {
      this.steps = steps;
      this.currentStepIndex = 0;
      this.showOverlay = true;
      this.showTour = true;
      document.documentElement.classList.add("no-scroll");
      this.focusElement();
    },
    nextStep() {
      if (this.currentStepIndex < this.steps.length - 1) {
        this.removeHighlight();
        this.currentStepIndex++;
        this.focusElement();
      }
    },
    prevStep() {
      if (this.currentStepIndex > 0) {
        this.removeHighlight();
        this.currentStepIndex--;
        this.focusElement();
      }
    },
    endTour() {
      this.removeHighlight();
      this.currentStepIndex = 0;
      this.steps = [];
      this.showOverlay = false;
      this.showTour = false;
      this.currentStepPosition = { top: 0, left: 0 };
      document.documentElement.classList.remove("no-scroll");
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    updateTooltipPosition() {
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        const step = this.steps[this.currentStepIndex];
        if (step && step.elementId) {
          const element = document.getElementById(step.elementId);
          if (element) {
            const rect = element.getBoundingClientRect();
            const tooltip = document.querySelector(".tooltip-tour");
            const tooltipWidth = tooltip.offsetWidth;
            const tooltipHeight = tooltip.offsetHeight;
            const viewportWidth = window.innerWidth;
            const viewportHeight = window.innerHeight;
            const buttonWidth = 40;

            let top = rect.top - tooltipHeight - 20;
            let left = rect.left;

            // Ajustar la posición del tooltip si se sale de la pantalla
            if (top < 0) {
              top = rect.bottom + 10;
              tooltip.classList.remove("below");
              tooltip.classList.add("above");
            } else {
              tooltip.classList.remove("above");
              tooltip.classList.add("below");
            }
            if (left + tooltipWidth + buttonWidth > viewportWidth) {
              left = viewportWidth - tooltipWidth - buttonWidth; // Ajustar si se sale del borde derecho
            }

            // Ajustar para que el tooltip no se salga de la parte inferior
            if (top + tooltipHeight > viewportHeight) {
              top = viewportHeight - tooltipHeight;
            }

            // Ajustar para que el tooltip no se salga de la parte izquierda
            if (left < 0) {
              left = 0;
            }

            this.tooltipPosition = { top, left };
          }
        }
      }, 50);
    },

    focusElement() {
      const step = this.steps[this.currentStepIndex];
      if (step && step.elementId) {
        const element = document.getElementById(step.elementId);
        if (element) {
          const rect = element.getBoundingClientRect();
          if (!this.isElementInViewport(element)) {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
          }
          this.currentStepPosition = {
            top: rect.bottom + window.scrollY + 10,
            left: rect.left + window.scrollY,
          };
          element.classList.add("highlight");
          setTimeout(() => {
            this.updateTooltipPosition();
          }, 100);
        }
      }
    },
    isElementInViewport(el) {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    },
    removeHighlight() {
      const step = this.steps[this.currentStepIndex];
      if (step && step.elementId) {
        const element = document.getElementById(step.elementId);
        if (element) {
          element.classList.remove("highlight");
        }
      }
    },
  },
  computed: {
    currentStep() {
      return this.steps[this.currentStepIndex];
    },
  },
};
</script>

<style>
.overlay {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  pointer-events: auto;
}

.tour {
  position: relative;
  z-index: 1005;
}

.tooltip-tour {
  position: fixed;
  background-color: #102444;
  padding: 10px 20px;
  border-radius: 10px;
  min-width: 290px;
  max-width: 290px;
  min-height: 160px;
  z-index: 1001;
  cursor: grab;
  transition: top 0.3s, left 0.3s;
  user-select: none;
}

.tooltip-tour:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  left: 50%;
  transform: translateX(-50%);
}

.tooltip-tour.below:after {
  top: 100%;
  bottom: auto;
  border-top: 10px solid #102444;
}

.tooltip-tour.above:after {
  bottom: 100%;
  top: auto;
  border-bottom: 10px solid #102444;
}

.tooltip-tour-content {
  color: white;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

/* Resaltado del elemento activo */
.highlight {
  position: relative;
  z-index: 1001;
  transition: all 0.3s ease;
  pointer-events: none;
  user-select: none;
}

.highlight::before {
  content: "";
  position: absolute;
  top: -10px;
  bottom: -10px;
  left: -10px;
  right: -10px;
  /* background-color: rgba(0, 0, 0, 0.3); */
  z-index: -1;
  border-radius: 10px;
}

.no-scroll {
  overflow: hidden;
}

.progress-tour {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  cursor: default;
  padding: 10px;
}

.step-dot {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  cursor: pointer;
}

.step-dot.completed {
  background-color: #ccc;
}

.step-dot.active {
  background-color: #df122e;
}

.step-dot:not(.completed):not(.active) {
  background-color: rgba(223, 18, 46, 0.5);
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
}
.next-button,
.end-button {
  position: absolute;
  top: 50%;
  right: 0;
  background-color: #102444;
  transform: translate(50%, -50%);
  border: 2px solid white;
  font-size: 16px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 0.25s;
}

.end-button:hover {
  border-color: #16a275;
}
.next-button:hover {
  border-color: #df122e;
}

.end-button:focus,
.end-button:focus-visible {
  border: 3px solid #16a275;
}

.next-button:focus,
.next-button:focus-visible {
  border: 3px solid #df122e;
}
</style>
