<template>
  <div class="row text-center pt-4 pb-2">
    <div v-for="itemPesv of items" :key="itemPesv.id" :class="itemPesv.Id == 1
      ? 'col-xl-2 col-md-2 col-sm-4  offset-sm-0'
      : 'col-xl-2 col-md-2 col-sm-4'
      ">
      <div class="d-flex justify-content-center">

        <vs-button border success style="width:  4.5rem; height:  4.5rem; font-size: 2.5rem;" 
          :active="activePasoFase1 == itemPesv.Id ? true : false"
           @click="updatePaso(itemPesv.Id)"
          :id="`${itemPesv.Id == 1 ? 'seleccion' : ''}`">
          <i :class="(itemPesv.Id== 1 ? 'fa-solid fa-brain' : itemPesv.Id== 2 ? 'fa-solid fa-tasks' : itemPesv.Id== 3 ? 'fa-solid fa-chart-line' : itemPesv.Id== 4 ? 'fa-solid fa-arrow-up-right-dots' : itemPesv.Id== 5 ? 'fa-solid fa-arrow-up-right-dots' : '' )"></i>
          <!-- {{ itemPesv.Id }} -->
        </vs-button>
      </div>
      <p class="pt-3 h4">{{ itemPesv.DescripcionFase }}</p>
    </div>
    <div class="col-xl-2 col-md-2 col-sm-4">
      <div class="d-flex justify-content-center">

        <vs-button border success style="width:  4.5rem; height:  4.5rem; font-size: 2.5rem;" id="files" @click="updatePaso(5)" class=" "
          :class="activePasoFase1 == 5 ? 'active' : ''">
          <i class="fa-solid fa-file"></i>
        </vs-button>
      </div>
      <p class="pt-3 h4">Archivos PESV</p>
    </div>
    <div class="col-xl-2 col-md-2 col-sm-4">
      <div class="d-flex justify-content-center">

        <vs-button border success style="width: 4.5rem; height:  4.5rem; font-size: 2.5rem;" id="results" class=" "
          :class="activePasoFase1 == 6 ? 'active' : ''" @click="updatePaso(6)">
          <i class="fa-solid fa-square-poll-vertical"></i>
        </vs-button>
      </div>
      <p class="pt-3 h4">Resultados</p>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState("pesv", ["activePasoFase1"]),
  },
  methods: {
    ...mapMutations("pesv", ["setPasoFase1"]),
    updatePaso(newPaso) {
      this.setPasoFase1(newPaso);
    },
  },
};
</script>

<style scoped>
/* btns fases */
.btn-evaluacion {
  border: solid 3px #16a275;
}

.btn-evaluacion:hover {
  border-color: rgb(18, 209, 223);
}

.btn-evaluacion.active {
  border-color: #df122e;
}
</style>
