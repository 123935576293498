var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.showOverlay)?_c('div',{staticClass:"overlay"}):_vm._e(),(_vm.showTour)?_c('div',{staticClass:"tour"},[(_vm.currentStep)?_c('div',{staticClass:"tooltip-tour",style:({
        top: _vm.tooltipPosition.top + 'px',
        left: _vm.tooltipPosition.left + 'px',
        cursor: _vm.isDragging ? 'grabbing' : 'grab',
      }),on:{"mousedown":_vm.startDragging,"touchstart":_vm.startDragging,"mousemove":_vm.drag,"touchmove":_vm.drag,"mouseup":_vm.endDragging,"touchend":_vm.endDragging,"mouseleave":_vm.endDragging}},[_c('div',{staticClass:"tooltip-tour-content"},[_c('div',{staticClass:"pr-2"},[_c('h4',{staticClass:"my-3"},[_c('b',[_vm._v(_vm._s(_vm.currentStep.title))])]),_c('p',[_vm._v(" "+_vm._s(_vm.currentStep.description)+" ")])]),_c('div',{staticClass:"progress-tour"},_vm._l((_vm.steps),function(step,index){return _c('span',{key:index,staticClass:"step-dot",class:{
              completed: index < _vm.currentStepIndex,
              active: index === _vm.currentStepIndex,
            },on:{"click":function($event){return _vm.goToStep(index)}}})}),0),_c('div',{staticClass:"buttons"},[(_vm.currentStepIndex < _vm.steps.length - 1)?_c('button',{staticClass:"next-button fas fa-chevron-right text-white",on:{"click":_vm.nextStep}}):_vm._e(),(_vm.currentStepIndex === _vm.steps.length - 1)?_c('button',{staticClass:"end-button fas fa-check text-white",on:{"click":_vm.endTour}}):_vm._e(),_c('i',{staticClass:"close-button fas fa-xmark",on:{"click":_vm.endTour}})])])]):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }